@import '../../assets/style/variables.module.scss';

.home {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .wrapper {
      max-width: 70vh;
    }

    .photoWrapper {
      width: 100%;
      position: relative;
      .original {
        height: 30vh;
      }
      .photoPixel {
        position: absolute;
        filter: drop-shadow(-1px -1px 5px rgba(0, 0, 0, 0.19));
        border: 1px solid #b0b0b0;
        height: 27vh;
        top: -3vh;
      }
    }

    .info {
      margin-top: 2vh;
      h4 {
        font-size: 2.2vh;
        font-family: $--medium25;
        color: $--shade1;
      }

      .logo {
        width: 70vh;
        margin-top: 2vh;
      }

      p {
        font-size: 1.6vh;
        font-family: $--light25;
        line-height: 3vh;
        margin-top: 2vh;
      }
    }
  }

  .wheelWrapper {
    width: 400px;
    height: 400px;
    position: absolute;
    right: -180px;
    bottom: -180px;

    .wheel {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .wheelPiece {
        width: 40px;
        height: 400px;
        background: red;
        border: 1px solid blue;
        position: absolute;
        box-shadow: -4px -4px 7px rgba(255, 255, 255, 0.18);
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          width: 60px;
          height: 600px;
          z-index: 1;
        }
      }
      .button {
        width: 60px;
        height: 60px;
        background: radial-gradient(
            110.83% 110.83% at 16.41% 22.4%,
            #ffffff 0%,
            #c9c9c9 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        z-index: 100;
        border-radius: 50%;
      }
    }

    .wheelBackground {
      width: 600px;
      height: 600px;
      position: absolute;
      right: -100px;
      bottom: -100px;
      filter: blur(40px);
    }
  }
}

.header {
  width: 100%;
  height: 50px;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  padding: 0 65px;
  box-sizing: border-box;
  align-items: center;
  z-index: 2;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  .mail {
    font-family: $--medium25;
    font-size: 14px;
  }

  img {
    height: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .home {
    .content {
      .wrapper {
        width: 90%;
        margin-bottom: 100px;
        .photoWrapper {
          align-items: center;
          justify-content: center;
          display: flex;

          .original {
            height: 20vh;
          }
        }

        .info {
          h4 {
            text-align: center;
          }

          .logo {
            width: 100%;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    .wheelWrapper {
      left: unset;
      right: unset;
      margin: 0 auto;
      bottom: -200px;
    }
  }

  .header {
    width: 100%;
    align-items: center;
    justify-content: center;

    .right {
    }
  }

  .hamburgerMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    z-index: 301;
    padding: 25px;
    box-sizing: border-box;

    button {
      background: transparent;
      width: 35px;
      height: 35px;
    }
  }

  .mobileHeader {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    width: 100%;
    height: 100%;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .mail {
        font-size: 1.5vh;
        font-family: $--medium25;
        color: white;
      }

      .social {
        width: 35px;
        height: 35px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
