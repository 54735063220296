@font-face {
  font-family: 'Sharp Grotesk Medium 25';
  src: url('../fonts/SharpGrotesk-Medium25.woff2') format('woff2'),
    url('../fonts/SharpGrotesk-Medium25.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk Light 25';
  src: url('../fonts/SharpGrotesk-Light25.woff2') format('woff2'),
    url('../fonts/SharpGrotesk-Light25.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk Bold 20';
  src: url('../fonts/SharpGrotesk-Bold20.woff2') format('woff2'),
    url('../fonts/SharpGrotesk-Bold20.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk Light 15';
  src: url('../fonts/SharpGrotesk-Light15.woff2') format('woff2'),
    url('../fonts/SharpGrotesk-Light15.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk SemiBold 15';
  src: url('../fonts/SharpGrotesk-SemiBold15.woff2') format('woff2'),
    url('../fonts/SharpGrotesk-SemiBold15.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk Book 20';
  src: url('../fonts/SharpGrotesk-Book20.woff2') format('woff2'),
    url('../fonts/SharpGrotesk-Book20.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$--medium25: 'Sharp Grotesk Medium 25';
$--light25: 'Sharp Grotesk Light 25';
$--bold20: 'Sharp Grotesk Bold 20';
$--light15: 'Sharp Grotesk Light 15';
$--semibold15: 'Sharp Grotesk SemiBold 15';
$--book20: 'Sharp Grotesk Book 20';

$--shade1: #192434;
