@import '../../assets/style/variables.module.scss';

.workDetail {
  display: flex;
  flex-direction: column;
  align-items: center;

  .headInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    h1 {
      font-family: $--bold20;
      font-size: 6vh;
    }

    h2 {
      font-family: $--semibold15;
      font-size: 3vh;
    }

    h3 {
      font-family: $--light15;
      font-size: 3vh;
    }

    h4 {
      font-family: $--book20;
      margin-top: 2vh;
      font-size: 3vh;
    }

    div {
      display: flex;
      margin-top: 2vh;
      gap: 2vh;
    }
  }
  .noBorder {
    width: 100%;
    display: flex;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .imageWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    padding-bottom: 100px;
    gap: 150px;
    margin-top: 150px;
    justify-content: space-between;
    flex-wrap: wrap;
    .full {
      width: 100%;
    }

    .mobile {
      width: 40%;
    }
  }

  .light {
    h1,
    h2,
    h3,
    h4 {
      color: white;
    }
  }

  .columnWrapper {
    width: 100%;
    display: flex;
    max-width: 1200px;
    gap: 150px;

    .column {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 150px;

      img {
        width: 100%;
      }
    }
  }
}

.bottomWork {
  position: fixed;
  bottom: 0;
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    font-family: $--bold20;
  }
}

.header {
  width: 100%;
  height: 50px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 0 65px;
  box-sizing: border-box;
  background: rgba($color: #000000, $alpha: 0.1);
  align-items: center;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  .mail {
    font-family: $--medium25;
    font-size: 14px;
  }

  img {
    height: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .workDetail {
    .headInfo {
      h1 {
        font-size: 5vh;
        text-align: center;
      }
      h2,
      h3,
      h4 {
        font-size: 2vh;
      }
    }

    .imageWrapper {
      width: 90%;
      margin-top: 50px;
    }
    .columnWrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      .column {
        width: 100%;
      }
    }
  }

  .hamburgerMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 301;
    padding: 25px;
    box-sizing: border-box;

    .logo {
      width: 130px;

      img {
        width: 100%;
      }
    }

    button {
      background: transparent;
      width: 35px;
      height: 35px;
    }
  }

  .mobileHeader {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    width: 100%;
    height: 100%;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .mail {
        font-size: 1.5vh;
        font-family: $--medium25;
        color: white;
      }

      .social {
        width: 35px;
        height: 35px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
